import { Formik } from 'formik'
import { Form } from 'formik-antd'
import { FC } from 'react'
import * as yup from 'yup'
import {
  AuthFormError,
  Button,
  Centered,
  FormikInnerContainer,
  FormSectionContainer,
  HeadingOne,
  Input,
  InputLabel,
  InvisibleRecaptchaField,
  PasswordInput,
  RecaptchaField,
  SpaceBetween,
  Spacer,
  StyledLink,
} from '@netpurpose/np-ui'
import { useAuth } from '#context/auth'
import { config } from '#services/config'
import { LoginActionsContainer } from './Login.style'

const SUBMIT_BUTTON_ID = 'submit-login-email'

type FormFields = {
  username: string
  password: string
} & RecaptchaField

const initialValues: FormFields = {
  username: '',
  password: '',
}

export const EnterEmailPassword: FC = () => {
  const { login, authError } = useAuth()
  return (
    <>
      <HeadingOne>Sign in</HeadingOne>
      <Spacer orientation="horizontal" spacing={0} />
      <Formik
        initialValues={initialValues}
        validationSchema={yup.object({
          username: yup.string().email('Must be a valid email').required('Required'),
          password: yup.string().required('Required'),
        })}
        onSubmit={({ username, password, recaptchaVerifier }) =>
          login(username, password, recaptchaVerifier)
        }
      >
        <Form style={{ height: '100%' }}>
          <InvisibleRecaptchaField formSubmitButtonId={SUBMIT_BUTTON_ID} />
          <FormikInnerContainer>
            <FormSectionContainer>
              <InputLabel htmlFor="username">Email</InputLabel>
              <Form.Item name="username">
                <Input
                  id="username"
                  name="username"
                  placeholder="Enter email address"
                  suffix={<span />}
                />
              </Form.Item>
              <InputLabel htmlFor="password">Password</InputLabel>
              <Form.Item name="password">
                <PasswordInput
                  id="password"
                  name="password"
                  placeholder="Enter password"
                  suffix={<span />}
                />
              </Form.Item>
              <LoginActionsContainer>
                <Form.Item name="submit" wrapperCol={{ offset: 0 }}>
                  <Button
                    level="tertiarySubmit"
                    disabled={false}
                    block
                    id={SUBMIT_BUTTON_ID}
                    size="large"
                  >
                    Sign in
                  </Button>
                </Form.Item>
                <AuthFormError {...(authError ? { error: authError } : {})} />
                <SpaceBetween>
                  Don&apos;t have an account?
                  <StyledLink href={config.routes.signup}>Sign up</StyledLink>
                </SpaceBetween>
                <Centered>
                  <StyledLink href={config.routes.forgotPassword}>Forgot password?</StyledLink>
                </Centered>
              </LoginActionsContainer>
            </FormSectionContainer>
          </FormikInnerContainer>
        </Form>
      </Formik>
    </>
  )
}
