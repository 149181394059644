import { FC, SetStateAction, useEffect, useState } from 'react'
import { MultiFactorResolverWithPhoneMFAHint, SSOProviderId } from '@netpurpose/api'
import { Button } from '@netpurpose/np-ui'
import AuthPage from '#components/auth/AuthPage'
import EnterMFACode from '#components/auth/EnterMFACode'
import { useAuth } from '#context/auth'
import { EnterEmailPassword } from './EnterEmailPassword'
import { FooterContainer, FooterText } from './Login.style'
import { SignInWithSSO, SignInWithSSOButton } from './SignInWithSSO'

const SignInWithSSOFooter = ({
  providers,
  setStep,
}: {
  providers: SSOProviderId[]
  setStep: (step: SetStateAction<Step>) => void
}) => (
  <FooterContainer>
    <FooterText>OR</FooterText>
    {providers.length === 1 && providers[0] ? (
      <SignInWithSSOButton providerId={providers[0]} />
    ) : (
      <Button
        level="newSecondary"
        block
        size="large"
        fullWidth
        transparent
        onClick={() => setStep('signInWithSSO')}
      >
        Sign in with SSO
      </Button>
    )}
  </FooterContainer>
)

type Step = 'enterEmailPassword' | 'confirmMultifactor' | 'signInWithSSO'

export type LoginProps = {
  loginProviders?: (SSOProviderId | 'email')[] | undefined
}

export const Login: FC<LoginProps> = ({ loginProviders = ['email'] }) => {
  const { mfaResolver, mfaVerificationId, authError, confirmLoginMFA, clearMFAState } = useAuth()

  const SSOLoginProviders = loginProviders.filter((id) =>
    Object.values(SSOProviderId).includes(id),
  ) as SSOProviderId[]

  const defaultCurrentStep = loginProviders.includes('email')
    ? 'enterEmailPassword'
    : 'signInWithSSO'

  const [currentStep, setCurrentStep] = useState<Step>(defaultCurrentStep || 'enterEmailPassword')

  useEffect(() => {
    if (mfaResolver && mfaVerificationId && currentStep !== 'confirmMultifactor') {
      setCurrentStep('confirmMultifactor')
    }
  }, [currentStep, mfaResolver, mfaVerificationId])

  const showBackButton = loginProviders.includes('email') && currentStep === 'signInWithSSO'

  const getForm = () => {
    switch (currentStep) {
      case 'enterEmailPassword':
        return {
          content: <EnterEmailPassword />,
          footer: !!SSOLoginProviders.length ? (
            <SignInWithSSOFooter
              providers={SSOLoginProviders}
              setStep={() => setCurrentStep('signInWithSSO')}
            />
          ) : null,
        }
      case 'confirmMultifactor':
        if (!mfaResolver || !mfaVerificationId) {
          return {
            content: <EnterEmailPassword />,
            footer: !!SSOLoginProviders.length ? (
              <SignInWithSSOFooter
                providers={SSOLoginProviders}
                setStep={() => setCurrentStep('signInWithSSO')}
              />
            ) : null,
          }
        }
        return {
          content: (
            <EnterMFACode
              onSubmit={async (code) => {
                await confirmLoginMFA(code, mfaVerificationId as string, mfaResolver)
              }}
              onCancel={() => {
                clearMFAState()
                setCurrentStep('enterEmailPassword')
              }}
              phoneNumber={
                (mfaResolver as MultiFactorResolverWithPhoneMFAHint).hints[0]?.phoneNumber || ''
              }
              {...(authError ? { error: authError } : {})}
            />
          ),
          footer: null,
        }
      case 'signInWithSSO':
        return {
          content: (
            <SignInWithSSO
              providers={SSOLoginProviders}
              onBack={() => setCurrentStep('enterEmailPassword')}
              showBackButton={showBackButton}
            />
          ),
          footer: null,
        }
      default:
        return {
          content: null,
          footer: null,
        }
    }
  }

  return (
    <AuthPage
      title="Login"
      FormFooter={getForm().footer}
      withBackButton={showBackButton}
      SSOSignInEnabled={!!SSOLoginProviders.length}
    >
      {getForm().content}
    </AuthPage>
  )
}
