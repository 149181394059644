import styled from 'styled-components'

export const LoginActionsContainer = styled.div`
  margin-top: 0.5rem;
  > * {
    margin-bottom: 1rem;
  }

  & > *:last-child {
    margin-bottom: 0;
  }
`

export const FooterContainer = styled.div<{ $evenPadding?: boolean }>`
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${({ $evenPadding }) => ($evenPadding ? '1.5rem 2.5rem' : '1rem 2.5rem 1.5rem')};
`

export const FooterText = styled.span`
  color: ${({ theme }) => theme.colors.architecture6};
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: bold;
  margin-bottom: 1rem;
`
