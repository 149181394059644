import styled from 'styled-components'
import { IconButton, Spacer } from '@netpurpose/np-ui'

const ButtonContainer = styled.div`
  padding: 0.75rem 0;
`

export const GoBack = ({ onBack }: { onBack: () => void }) => (
  <div>
    <ButtonContainer>
      <IconButton icon="ArrowBack" iconAlt="back" onClick={onBack} alignCenter />
    </ButtonContainer>
    <Spacer orientation="horizontal" spacing={0} />
  </div>
)
