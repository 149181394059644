import styled from 'styled-components'
import { loginWithSSO, SSOProviderId, SSOProviderMap } from '@netpurpose/api'
import { Button, HeadingOne, SignInWithGoogle, Spacer } from '@netpurpose/np-ui'
import { GoBack } from './GoBack'

const Container = styled.div`
  > * {
    margin-bottom: 1.5rem;
  }
`

const ButtonsContainer = styled.div`
  > * {
    margin-bottom: 0.5rem;
  }
`
export const SignInWithSSOButton = ({ providerId }: { providerId: SSOProviderId }) => {
  if (providerId === SSOProviderId.Google) {
    return <SignInWithGoogle />
  }

  return (
    <Button
      level="newSecondary"
      block
      size="large"
      fullWidth
      transparent
      onClick={() => loginWithSSO(providerId)}
    >
      Sign in with {SSOProviderMap[providerId]}
    </Button>
  )
}

export const SignInWithSSO = ({
  onBack,
  providers,
  showBackButton,
}: {
  onBack: () => void
  providers: SSOProviderId[]
  showBackButton: boolean
}) => (
  <Container>
    {showBackButton && <GoBack onBack={onBack} />}
    <HeadingOne>Sign in with SSO</HeadingOne>
    {!showBackButton && <Spacer orientation="horizontal" spacing={0} />}
    <ButtonsContainer>
      {providers.map((providerId) => (
        <SignInWithSSOButton key={providerId} providerId={providerId} />
      ))}
    </ButtonsContainer>
  </Container>
)
