import { FC } from 'react'
import { useRedirectWithOriginalUrl } from '@netpurpose/core'
import { useAuth } from '#context/auth'
import { config } from '#services/config'

export const ShowIfLoggedOut: FC<{ children: React.ReactNode }> = ({ children }) => {
  const { isAuthenticated, isAuthInitialised } = useAuth()
  const { handleRedirect } = useRedirectWithOriginalUrl({
    landingPageUrl: config.routes.landingPage,
  })

  if (!isAuthInitialised) {
    return null
  }

  if (isAuthenticated) {
    handleRedirect()
    return null
  }

  return <>{children}</>
}
