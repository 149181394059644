import { GetServerSideProps } from 'next'
import { SSOProviderId } from '@netpurpose/api'
import { isProductionEnv } from '@netpurpose/utils'
import { Login, LoginProps } from '#components/auth/Login'
import { ShowIfLoggedOut } from '#components/common/ShowIfLoggedOut'

export const getServerSideProps = (async (context) => {
  const { headers } = context.req
  // Note that for this to work locally, you'll need to update your /etc/hosts
  // file with e.g. 127.0.0.1 foobar.localhost
  const url = `${headers['x-forwarded-proto'] || 'https'}://${headers.host}/api/login-methods-for-domain`

  const res = await fetch(url, {
    method: 'GET',
  })

  if (res.status === 200) {
    const providers = await res.json()
    return {
      props: {
        loginProviders: providers as (SSOProviderId | 'email')[],
      },
    }
  }

  return { props: {} }
}) satisfies GetServerSideProps<LoginProps>

// NOTE: currently with our Vercel config staging login options can't be fetched from domain api
// hardcoding here to make sign-in with Google accessible with one click
const stagingLoginProviders = ['email', SSOProviderId.Google] as LoginProps['loginProviders']

const ProtectedLogin = ({ loginProviders }: LoginProps) => {
  const providers = isProductionEnv() ? loginProviders : stagingLoginProviders

  return (
    <ShowIfLoggedOut>
      <Login loginProviders={providers} />
    </ShowIfLoggedOut>
  )
}

export default ProtectedLogin
