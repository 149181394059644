import { Formik } from 'formik'
import { Form } from 'formik-antd'
import { FC } from 'react'
import * as yup from 'yup'
import { AuthError } from '@netpurpose/api'
import {
  ActionsContainer,
  AuthFormError,
  BodyText,
  Button,
  FormikInnerContainer,
  FormSectionContainer,
  HeadingText,
  Icon,
  Input,
  InputLabel,
  InvisibleRecaptchaField,
  LargeBodyText,
  RecaptchaField,
  Spacer,
} from '@netpurpose/np-ui'

type FormFields = {
  code: string
} & RecaptchaField

const initialValues: FormFields = {
  code: '',
}

type Props = {
  phoneNumber: string
  onSubmit: (code: string) => Promise<void>
  onCancel: () => void
  error?: AuthError
}

const SUBMIT_BUTTON_ID = 'confirm-mfa-code-submit'

const EnterMFACode: FC<Props> = ({ phoneNumber, onSubmit, onCancel, error }) => (
  <>
    <HeadingText>Enter your code</HeadingText>
    <Spacer orientation="horizontal" spacing={0} />
    <FormSectionContainer>
      <LargeBodyText>
        We just sent a text message to <strong>{phoneNumber}</strong> with a code for you to enter
        here.
      </LargeBodyText>
    </FormSectionContainer>
    <Formik
      initialValues={initialValues}
      validationSchema={yup.object({
        code: yup.string().required('Required'),
      })}
      onSubmit={({ code }) => onSubmit(code)}
    >
      <Form style={{ height: '100%' }}>
        <InvisibleRecaptchaField formSubmitButtonId={SUBMIT_BUTTON_ID} />
        <FormikInnerContainer>
          <FormSectionContainer>
            <InputLabel htmlFor="code">Code</InputLabel>
            <Form.Item name="code">
              <Input
                id="code"
                name="code"
                placeholder="Enter code"
                prefix={<Icon icon="Key" alt="key icon" color="typeTertiary" />}
              />
            </Form.Item>
            <AuthFormError {...(error ? { error } : {})} />
            <BodyText>Note: This could take a minute.</BodyText>
          </FormSectionContainer>
          <ActionsContainer>
            <Button level="secondary" onClick={onCancel}>
              Back
            </Button>
            <Button level="tertiarySubmit" id={SUBMIT_BUTTON_ID} disabled={false}>
              Verify &amp; Sign In
            </Button>
          </ActionsContainer>
        </FormikInnerContainer>
      </Form>
    </Formik>
  </>
)

export default EnterMFACode
