import Image from 'next/image'
import styled from 'styled-components'
import { loginWithGoogle } from '@netpurpose/api'
import { Button } from '@netpurpose/np-ui'

const Content = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

export const SignInWithGoogle = () => {
  const signIn = async () => {
    try {
      await loginWithGoogle()
      // NOTE: this is to prevent an error being thrown if user cancels the popup
    } catch (error) {}
  }

  return (
    <Button
      key="Google"
      level="newSecondary"
      block
      size="large"
      fullWidth
      transparent
      onClick={signIn}
    >
      <Content>
        <Image
          src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
          alt="Google"
          width={24}
          height={24}
          priority
        />
        Sign in with Google
      </Content>
    </Button>
  )
}
