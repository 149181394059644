import { RecaptchaVerifier } from 'firebase/auth'
import { useFormikContext } from 'formik'
import { FC, useEffect } from 'react'
import { auth, RecaptchaFailedError } from '@netpurpose/api'

declare global {
  interface Window {
    recaptchaVerifier?: RecaptchaVerifier
  }
}

export type RecaptchaField = {
  recaptchaVerifier?: RecaptchaVerifier
}

type Props = {
  formSubmitButtonId: string
}

export const InvisibleRecaptchaField: FC<Props> = ({ formSubmitButtonId }) => {
  const { setFieldValue } = useFormikContext()

  useEffect(() => {
    try {
      const recaptchaVerifier = new RecaptchaVerifier(auth, formSubmitButtonId, {
        size: 'invisible',
      })
      setFieldValue('recaptchaVerifier', recaptchaVerifier)

      return () => {
        recaptchaVerifier.clear()
      }
    } catch (err) {
      // @ts-expect-error
      throw new RecaptchaFailedError(err)
    }
  }, [formSubmitButtonId, setFieldValue])

  return null
}
